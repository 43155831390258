import {
  SET_SEA_FCL_JSON,
  SET_SEA_LCL_JSON,
  SET_AIR_JSON,
  SET_QUERY_PARAMS,
  SET_URL,
  UPDATE_ERROR,
  SET_PORT_ORIGIN_JSON,
  SET_PORT_DESTINATION_JSON,
  SET_FILTER_BY_CHEAPER,
  SAVE_SEARCH_FORM_PARAM,
  SET_ORIGINAL_RATE_JSON,
  SET_NEW_RATE_REMAINING_TIME,
  SET_NEW_RATES_APPEAR,
  SEARCH_SHOW_NEW_RATE_CONTACT_FORM,
  UPDATE_POSTAL_ZIP_CODE,
  UPDATE_PICKUP_DELIVERY_ERS_LINE,
  RE_SORT_RATE_CARD,
  SET_JWT_TOKEN,
    SET_CLIENT_JWT,
  SET_USER_DETAILS,
    SET_JWT_TOKEN_TIME,
    SET_FACTORY_FOR_USER,
    NEW_FACTOR_FOR_PICKUP,
  FINAL_SEARCH_FORM,
  FILTER_RATES_BY_CARRIER,
  SET_DISCOUNT,
} from '../actions/types';

/**
 * @module route_reducer
 * @redux
 * @description It the redux reducer for Search page.
 * @category Search Page
 * @subcategory reducer
 */

/**
 * @type {State}
 * @property {Object} seaFCLJSON - sea FCL rate json
 * @property {Object} seaLCLJSON - sea LCL rate json
 * @property {Object} seaFCLJSON - sea AIR rate json
 *
 * @property {Object} originalRateData - original/initial rate json and loading spinner in uuid mode
 * @property {Object} originalRateData.rateJson - original/initial rate json in uuid mode
 * @property {boolean} originalRateData.loading - whether to show searching spinner for uuid requests below sorting radio button and below rate cards
 *
 * @property {Object} queryParams - query params
 * @property {Object} queryParams.from - origin port e.g. `{ city: "Coffs Harbour", id: 3434, country:"Australia"}`
 * @property {Object} queryParams.to - destination port e.g. `{ city: "Nanaimo", id: 3434, country:"Canada"}`
 * @property {string} queryParams.date - date e.g. `20 May, 2021`
 * @property {string} queryParams.type - transport mode e.g. `api-fcl`, `api-lcl`, `api-air`
 *
 * @property {string} url - url (it has relevant params) so that it can be used in browser address field
 * @property {string} errorMessage - in-app error message notify, shown at bottom-right in case of network error, server error, etc.
 * @property {boolean} loading - should show a loading spinner
 *
 *
 * @property {Object} originPortJSON - origin ports json
 * @property {Object} destinationPortJSON - destination ports json
 *
 * @property {boolean} filterByCheaper - should sort rate cards based on price.
 * `true` - cheapest price rate card is shown at the top.
 * `false` - lowest transit time rate card is shown at the top.
 *
 * @property {boolean} showSearchContactForm - whether to show contact form
 * @property {Object} savedFormParams - save api request property form_params on search page, only when system can't find any rates or error happens during request
 * @property {string} newRatesRemainingTime - remaining time of new rates of UUID request e.g. `1 min 12 sec`
 *
 * @property {boolean | null} newRatesAppear - Do new rate cards present in new rates json by uuid. it is used for showing contact form if no rates appear at all.
 * @property {boolean} showNewRateContactForm -  whether to show contact form above result cards on search page
 * @property {Array} zipCodeArr - save all postal zip code post response obj in array
 * @property {boolean} updatePickupDeliveryErsLine - whether pickup delivery ers line component should be re-rendered
 * @property {boolean} reSortRateCard - re-sort rates cards upon clicking search btn
 *
 */

const INITIAL_STATE = {
  seaFCLJSON: null,
  seaLCLJSON: null,
  airJSON: null,
  originalRateData: {
    rateJson: null,
    loading: false,
  },
  queryParams: {
    from: '',
    to: '',
    date: '',
    type: 'api-fcl',
    discount: ''
  },
  url: '',
  errorMessage: '',
  discount: {},
  loading: false,
  // rollback these two ports data now from cache reducer
  originPortJSON: {},
  destinationPortJSON: {},
  filterByCheaper: true,
  showSearchContactForm: false,
  savedFormParams: {}, //old form POST request data, it's for when system can not find any rates/error happends during post request,
  newRatesRemainingTime: '',
  newRatesAppear: null, //do new rates present in uuid
  showNewRateContactForm: false,
  zipCodeArr: [], //save all zip code post reaponse obj
  updatePickupDeliveryErsLine: false, //it will re-render component upon changing it's value
  reSortRateCard: true, //re-sort rates cards upon clicking search btn
  JWT_TOKEN:{},
  USER_DETAILS:{},
  CLIENT_TOKEN:{},
  User_factor_and_shipper_Details:{},
  final_search_form:{}
};

/**
 * @memberof module:route_reducer
 * @description It's the route reducer.
 * @type {Redux.Reducer}
 *
 * @param {Redux.Store} state - The current redux state of the route reducer
 * @param {Redux.Action} action - Redux form action
 *
 * @returns {Redux.Store} The updated redux state
 *
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SEA_FCL_JSON:
      return {
        ...state,
        seaFCLJSON: action.payload,
        seaLCLJSON: {},
        airJSON: {},
        // filterByCheaper: true,
      };
    case FINAL_SEARCH_FORM:
      return {
        ...state,
        final_search_form:action.payload
      }

    case FILTER_RATES_BY_CARRIER:
      return {
        ...state,
        seaFCLJSON: { ...state.seaFCLJSON, ...action.payload },
      };

    case SET_SEA_LCL_JSON:
      return {
        ...state,
        seaLCLJSON: action.payload,
        seaFCLJSON: {},
        airJSON: {},
        // filterByCheaper: true,
      };
    case SET_AIR_JSON:
      // console.log('*******air json: ', action.payload);
      return {
        ...state,
        airJSON: action.payload,
        seaFCLJSON: {},
        seaLCLJSON: {},
        // filterByCheaper: true,
      };
    case SET_ORIGINAL_RATE_JSON:
      return {
        ...state,
        originalRateData: { ...state.originalRateData, ...action.payload },
      };

    case SET_QUERY_PARAMS:
      return { ...state, queryParams: action.payload };
    case SET_DISCOUNT:
      return { ...state, discount: action.payload };
    case SET_URL:
      return { ...state, url: action.payload };
    case UPDATE_ERROR:
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: action.payload.loading,
      };

    case SET_PORT_ORIGIN_JSON:
      return { ...state,
        originPortJSON: action.payload
      };

    case SET_PORT_DESTINATION_JSON:
      return { ...state, destinationPortJSON: action.payload };

    case SET_FILTER_BY_CHEAPER:
      return { ...state, filterByCheaper: action.payload };

    case SAVE_SEARCH_FORM_PARAM:
      return {
        ...state,
        savedFormParams: action.payload.form_params,
        showSearchContactForm: action.payload.show,
      };
    // it is for showing contact form in case no new rates appear by uuid
    case SEARCH_SHOW_NEW_RATE_CONTACT_FORM:
      return {
        ...state,
        showNewRateContactForm: action.payload,
      };
    case SET_NEW_RATE_REMAINING_TIME:
      return {
        ...state,
        newRatesRemainingTime: action.payload,
      };
    case SET_NEW_RATES_APPEAR:
      return {
        ...state,
        newRatesAppear: action.payload,
      };
    case UPDATE_POSTAL_ZIP_CODE:
      return {
        ...state,
        zipCodeArr: action.payload || [],
      };
    case UPDATE_PICKUP_DELIVERY_ERS_LINE:
      return {
        ...state,
        updatePickupDeliveryErsLine: state.updatePickupDeliveryErsLine
          ? false
          : true,
      };
    case RE_SORT_RATE_CARD:
      return {
        ...state,
        reSortRateCard: state.reSortRateCard ? false : true,
      };
    case SET_JWT_TOKEN:

      return {
        ...state,
        JWT_TOKEN:action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        USER_DETAILS :action.payload,
      }
    case SET_CLIENT_JWT:
      return {
        ...state,
        CLIENT_TOKEN:action.payload,
      }

    case SET_FACTORY_FOR_USER:
      return {
        ...state,
        User_factor_and_shipper_Details:action.payload,
      }
    default:
      return state;
  }
};
